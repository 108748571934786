import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    location: String,
  };

  connect() {
    // this.targetElement.style.setProperty("--animate-delay", "5s");
    this.targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    this.element.remove();
  }

  get targetElement() {
    return document.getElementById(this.locationValue);
  }
}

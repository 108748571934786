import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // allows only 1 select in group to be set

  static targets = [ 'input' ]

  connect() {
    this.element.addEventListener('change', (evt) => {
      let el = evt.target
      if (el.selectedIndex > 0) {
        // only react when non-empty value is set
        // (so we don't fire when select is cleared in handler)
        this.clearInputsExcept(el)
      }
    })
  }

  clearInputsExcept(elem) {
    this.inputTargets.forEach(e => {
      if (e != elem) {
        if (e.tomselect) {
          e.tomselect.clear()
        } else {
          e.selectedIndex = 0
        }
      }
    })
  }

}

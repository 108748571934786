import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // sort child elements alphabetically using locale-sensitive sorting by text content

  connect() {
    const parent = this.element
    const sortedChildren = Array.from(parent.children).sort((a,b) => a.textContent.localeCompare(b.textContent))
    parent.append(...sortedChildren)
  }

}

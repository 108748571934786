import { Controller } from '@hotwired/stimulus'
import { useHotkeys } from 'stimulus-use/hotkeys'

export default class extends Controller {

  connect() {
    useHotkeys(this, {
      'esc': [this.clickHandler]
    })
  }

  clickHandler() {
    this.element.click()
  }

}

import { Controller } from "@hotwired/stimulus"
import IMask from "imask/holder"
import 'imask/masked/number'

const maskOptions = {
  decimal: {
    mask: Number,
    scale: 1,
    thousandsSeparator: "",
    normalizeZeros: true,
    radix: '.',
    mapToRadix: [','],
    min: 0
  }
}

export default class extends Controller {

  static values = { type: String };

  connect() {
    IMask(this.element, maskOptions[this.typeValue])
  }
}

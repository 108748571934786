import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["selectable"]

  selected_class = "selected"

  select(event) {
    this.selectableTargets.forEach(el => el.classList.remove(this.selected_class))
    event.currentTarget.closest('.js-selectable').classList.add(this.selected_class)
  }

}

// paper utils - handle PDF download from edit_mode on paper controller
document.arrive('#js-paper-download', { existing: true }, (el) => {
  el.addEventListener('click', function(evt){
    evt.preventDefault()
    document.querySelector('#paper_print').value = 1
    document.querySelector('#js-paper-form').submit()
    document.querySelector('#paper_print').value = 0
  })
})

// hide flash notices after few seconds
document.arrive('.page-flash', { existing: true }, (el) => {
  setTimeout(() => {
    el.classList.add('animate__animated', 'animate__bounceOut')
    el.addEventListener('animationend', () => el.remove(), { once: true })
  }, 5000)
})

// scroll sidebar so that active menu item is visible
document.arrive('aside .nav-link.active', { existing: true }, (el) => {
  if (!Element.prototype.scrollIntoViewIfNeeded){
    //Firefox fallback
    el.scrollIntoView({block: 'nearest'});
  } else {
    el.scrollIntoViewIfNeeded()
  }
})

// set focus to first form field (only for ordinary inputs and selects)
document.arrive('form.simple_form:not([data-skip-focus="true"])[class*="new_"]', { existing: true }, (el) => {
  let field = el.querySelector('input.form-control, select')
  if (field) {
    field.focus()
  }
})

// BS tooltips
import { Tooltip } from 'bootstrap'
document.arrive('[data-bs-toggle="tooltip"]', { existing: true }, el => {
  new Tooltip(el)
})

// BS popovers
import { Popover } from 'bootstrap'
document.arrive('[data-bs-toggle="popover"]', { existing: true }, el => {
  new Popover(el, {
    html: true
  })
})

import { Controller } from "@hotwired/stimulus"
import TomSelect      from "tom-select"

export default class extends Controller {

  static values = {
    url: String,
    useColumns: Boolean
  }

  connect() {
    let options = { plugins: [] }
    if (this.hasUseColumnsValue) { options['plugins'].push('optgroup_columns') }
    if (this.hasUrlValue) {
      let remoteOptions = {
        valueField: 'id',
        labelField: 'text',
        create: false,
        persist: false,
        maxOptions: 15,
        loadThrottle: 200,
        closeAfterSelect: true,
        searchField: [],
        shouldLoad: (query) => { return query.length > 1 },
        load: (query, callback) => {
          var url = this.urlValue + '&q=' + encodeURIComponent(query)
          fetch(url)
            .then(response => response.json())
            .then(json => {
              this.ts.clearOptions() // https://github.com/orchidjs/tom-select/issues/497
              callback(json)
            })
            .catch(() => { callback() })
        }
      }
      options = { ...options, ...remoteOptions }
    }
    if (this.element.dataset.maxOptions) { options['maxOptions'] = this.element.dataset.maxOptions }
    if (this.element.dataset.disableSearching) { options['searchField'] = [] }
    if (this.element.dataset.create) { options['create'] = true }

    if (!this.ts) {
      // do not init when TS already exists (this may happen if the exact same page is loaded in sequence)
      this.ts = new TomSelect(this.element, options)
      if (this.isFirstField()) { this.ts.focus() }
    }
  }

  isFirstField() {
    let form = this.element.closest('form.simple_form:not([data-skip-focus="true"])[class*="new_"]')
    if (form) {
      let first = form.querySelector('input.form-control, select.form-select, select.form-control')
      return first == this.element
    } else { return false }
  }

}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // click on all select_all toggles in subtree

  static targets = ['checkboxAll']

  connect() {
    this.checkboxAllTarget.addEventListener('click', (e) => {
      this.element.querySelectorAll('[data-checkbox-select-all-target="checkboxAll"]').forEach(el => el.click())
    })
  }

}
